<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <a-select
        v-model:value="status"
        :placeholder="$t('Status')"
        style="min-width: 200px; width: 100%"
        :size="size"
        @change="handleFilter"
      >
        <a-select-option v-for="(v, key) in statusOptions" :key="key" :value="v">
          {{ $t(v) }}
        </a-select-option>
      </a-select>
    </div>
    <div class="column is-narrow">
      <InputProperty v-model:value="propertyId" style="min-width: 200px" @change="delayedFilter" />
    </div>
    <div class="column is-narrow">
      <InputHousekeeper v-model:value="userId" style="min-width: 200px" @change="delayedFilter" />
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
        @change="handleFilter"
      >
        <a-input :value="rangeString" :placeholder="$t('Date range')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div class="column">
      <a-button
        v-show="status || propertyId || userId || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Status": "Status",
    "created": "created",
    "waiting approval": "waiting approval",
    "approved": "approved",
    "rejected": "rejected",
    "payment in process": "payment in process",
    "Date range": "Date range",
    "Clear": "Clear"
  },
  "ja": {
    "Status": "ステータス",
    "created": "新規",
    "waiting approval": "未承認",
    "approved": "承認済み",
    "rejected": "却下",
    "payment in process": "支払い処理中",
    "Date range": "日付範囲",
    "Clear": "クリア"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import InputHousekeeper from '@/components/InputHousekeeper';
import { cleanObject } from '@/utils/util';
import { STATUS } from '@/config/expense';
import DateFormatMixin from '../mixins/DateFormat';

export default {
  name: 'ExpensesFilter',
  components: {
    InputProperty,
    InputHousekeeper,
    CalendarFilled,
  },
  mixins: [DateFormatMixin],
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter', 'clear-filter'],
  data() {
    return {
      range: [],
      status: undefined,
      propertyId: undefined,
      userId: undefined,
      statusOptions: STATUS,
    };
  },
  mounted() {
    this.status = this.$route.query.status || undefined;
    this.propertyId = this.$route.query.propertyId || undefined;
    this.userId = this.$route.query.userId || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
  },
  methods: {
    // eslint-disable-next-line func-names
    delayedFilter: debounce(async function () {
      await this.handleFilter();
    }, 600),
    async handleFilter() {
      const query = cleanObject({
        ...this.$route.query,
        status: this.status,
        propertyId: this.propertyId,
        userId: this.userId,
        ...this.formattedRange,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    },
    handleClear() {
      this.status = undefined;
      this.propertyId = undefined;
      this.userId = undefined;
      this.range = [];
      this.handleFilter();
    },
  },
};
</script>

<style scoped></style>
