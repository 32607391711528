<template>
  <Modal :mask-closable="true">
    <template #default="{ hide }">
      <div class="stock-chart">
        <h1 class="has-text-primary has-text-weight-bold is-size-4 mb-3">
          {{ $t('Number of stock usage') }}
        </h1>
        <table class="stock-chart__table">
          <tbody>
            <tr>
              <th>{{ $t('Property') }}</th>
              <td>
                {{ room.property ? room.property.name : '-' }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('Room') }}</th>
              <td>
                {{ room.name || '-' }}
              </td>
            </tr>
            <tr>
              <th>{{ $t('Date range') }}</th>
              <td>
                {{ dateRange }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="stock-chart__pie">
        <PieChart :data="chartData" :options="chartOptions" />
      </div>
      <div class="columns">
        <div class="column has-text-right is-12 m-t-0">
          <a-button size="large" style="min-width: 115px" type="primary" @click="() => hide()">
            {{ $t('OK') }}
          </a-button>
        </div>
      </div>
    </template>
    <template #handler="{ show }">
      <a class="ant-btn ant-btn-lg ant-btn-primary" @click.prevent.stop="show">
        {{ $t('Show Pie Chart') }}</a>
    </template>
  </Modal>
</template>

<i18n>
{
  "en": {
    "Number of stock usage": "Number of Stock usage",
    "Property": "Property",
    "Room": "Room",
    "Date range": "Date range",
    "OK": "OK"
  },
  "ja": {
    "Number of stock usage": "利用した数",
    "Property": "物件",
    "Room": "部屋",
    "Date range": "日付範囲",
    "OK": "OK"
  }
}
</i18n>

<script>
import Modal from '@/components/Modal';
import PieChart from '@/components/PieChart';

export default {
  name: 'StockChartModal',
  components: {
    Modal,
    PieChart,
  },
  props: {
    room: {
      type: Object,
      default: null,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['close'],
  data() {
    return {
      colors: [
        '#25CCF7',
        '#FD7272',
        '#54a0ff',
        '#39c1f9',
        '#00d2d3',
        '#1abc9c',
        '#2ecc71',
        '#3498db',
        '#9b59b6',
        '#34495e',
        '#16a085',
        '#27ae60',
        '#2980b9',
        '#8e44ad',
        '#2c3e50',
        '#f1c40f',
        '#e67e22',
        '#e74c3c',
        '#ecf0f1',
        '#95a5a6',
        '#f39c12',
        '#d35400',
        '#c0392b',
        '#bdc3c7',
        '#7f8c8d',
        '#55efc4',
        '#81ecec',
        '#74b9ff',
        '#a29bfe',
        '#dfe6e9',
        '#00b894',
        '#00cec9',
        '#0984e3',
        '#6c5ce7',
        '#ffeaa7',
        '#fab1a0',
        '#ff7675',
        '#fd79a8',
        '#fdcb6e',
        '#e17055',
        '#d63031',
        '#feca57',
        '#5f27cd',
        '#54a0ff',
        '#01a3a4',
      ],
      chartOptions: {
        responsive: true,
        legend: {
          position: 'right',
          align: 'start',
          fullWidth: true,
          maintainAspectRatio: true,
          labels: {
            boxWidth: 7,
            usePointStyle: true,
          },
        },
        layout: {
          padding: {
            top: 30,
            bottom: 0,
            right: 60,
          },
        },
      },
    };
  },
  computed: {
    chartData() {
      const { list } = this;

      if (!list || list.length === 0) {
        return {};
      }

      const labels = [];
      const data = [];
      const backgroundColor = [];
      const colors = [...this.colors];

      for (let i = 0; i < list.length; i += 1) {
        const { name, totalUsed } = list[i];
        const randomColor = colors.splice(Math.floor(Math.random() * colors.length), 1);

        labels.push(name);
        data.push(totalUsed);
        backgroundColor.push(randomColor);
      }

      return {
        labels,
        datasets: [{ data, backgroundColor }],
      };
    },
    dateRange() {
      const { dateFrom, dateTo } = this.$route.query;
      const { dateFormat } = this.$variables;

      if (dateFrom && dateTo) {
        const from = this.$filters.date(dateFrom, { format: dateFormat });
        const to = this.$filters.date(dateTo, { format: dateFormat });

        return `${from} - ${to}`;
      }

      return '';
    },
  },
  methods: {
    handleOk() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.stock-chart {
  h2,
  th {
    color: #888888;
  }
}

.stock-chart__pie {
  padding: 0 2rem;
}

.stock-chart__table {
  width: 100%;

  th {
    width: 8rem;
    padding: 1px 0;
    font-size: 14px;
    font-weight: normal;
  }

  td {
    color: #333333;
    padding: 1px 10px;
    font-size: 14px;
  }
}
</style>
