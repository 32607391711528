<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <h2 class="has-text-centered is-3 title">
            {{ $t('Download Reports') }}
          </h2>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container is-mobi">
        <div class="column is-12 m-t-4x has-text-centered">
          <a-button
            :loading="isSubmitting"
            size="large"
            class="m-r-3x"
            style="min-width: 155px"
            type="primary"
            @click="handleDownload"
          >
            {{ $t('Download Reservation Report') }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Download Reports": "Download Reports",
    "Download Reservation Report": "Download Reservation Report"
  },
  "ja": {
    "Download Reports": "Download Reports",
    "Download Reservation Report": "Download Reservation Report"
  }
}
</i18n>

<script>
import { saveAs } from 'file-saver';

export default {
  name: 'Download',
  components: {
  },
  data() {
    return {
      isSubmitting: false,
      ok: false,
      cancelOk: false,
    };
  },
  methods: {
    handleDownload() {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('reports/downloadReservationsReport')
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>
