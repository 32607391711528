export default {
  computed: {
    rangeString() {
      if (this.range && this.range.length === 0) {
        return null;
      }

      const dateFormat = this.dateFormat ? this.dateFormat : 'MM/DD/YYYY';

      const from = this.$filters.date(this.range[0], { format: dateFormat });
      const to = this.$filters.date(this.range[1], { format: dateFormat });

      return `${from} ~ ${to}`;
    },
    formattedRange() {
      const dateFormat = 'YYYY-MM-DD';
      const range = { dateFrom: undefined, dateTo: undefined };

      if (this.range && this.range.length) {
        range.dateFrom = this.$filters.date(this.range[0], { format: dateFormat });
        range.dateTo = this.$filters.date(this.range[1], { format: dateFormat });
      }

      return range;
    },
  },
};
