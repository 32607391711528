<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Expenses') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mini-gap is-mobi">
          <div class="column">
            <ExpensesFilter @filter="fetchData" />
          </div>
          <div v-if="hasSelectedRows" class="column is-narrow">
            <Modal>
              <template #default="{ hide }">
                <ExpenseRejectForm
                  :expenses="selectedRows"
                  @close="
                    () => {
                      hide();
                      fetchData();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <a class="ant-btn ant-btn-danger ant-btn-lg btn-reject" @click.prevent.stop="show">
                  {{ $t('Reject') }}</a
                >
              </template>
            </Modal>
          </div>
          <div v-if="hasSelectedRows" class="column is-narrow">
            <Modal>
              <template #default="{ hide }">
                <ExpenseApproveForm
                  :expenses="selectedRows"
                  @close="
                    () => {
                      hide();
                      fetchData();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <a
                  class="ant-btn ant-btn-lg ant-btn-primary btn-approve"
                  @click.prevent.stop="show"
                >
                  {{ $t('Approve') }}</a
                >
              </template>
            </Modal>
          </div>
          <div v-if="!hasSelectedRows" class="column is-narrow">
            <a-button
              class="ant-btn ant-btn-lg ant-btn-primary"
              type="primary"
              @click="handleExportCSV"
            >
              {{ $t('Export to CSV') }}
            </a-button>
          </div>
        </div>
        <a-table
          class="report-table"
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :row-selection="rowSelection"
          :custom-row="customRow"
          :pagination="pagination"
          :row-class-name="(record, index) => (record.status === 'approved' ? 'approved' : null)"
          :scroll="{ x: 1300 }"
          @change="handleTableChange"
        >
          <template #workDate="{ record }">
            {{ $filters.date(record.workDateStart, { format: $variables.dateFormat }) }}
          </template>
          <template #partner="{ record }">
            {{ getPartnerName(record) }}
          </template>
          <template #user="{ record }">
            {{ getUserName(record) }}
          </template>
          <template #property="{ record }">
            {{ record.property ? record.property.abbreviation : '' }}
          </template>
          <template #cost="{ record }">
            <span v-if="record.cost && record.currency">
              {{ $filters.currency(record.cost, { currency: record.currency }) }}
            </span>
            <span v-else>-</span>
          </template>
          <template #summary="{ record }">
            {{ $t(record.summary) }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
          <template #bearer="{ record }">
            {{ record.bearer }}
          </template>
          <template #payoffMethod="{ record }">
            {{ $t(record.payoffMethod || '-') }}
          </template>
          <template #taxRate="{ record }"> {{ record.taxRate }}% </template>
          <template #ownerBill="{ record }">
            {{ record.ownerBill }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Expenses": "Expenses",
    "Approve": "Approve",
    "Reject": "Reject",
    "Working day": "Working day",
    "Housekeeping Company": "Housekeeping Company",
    "Housekeeper": "Housekeeper",
    "Property": "Property",
    "Cost": "Cost",
    "Summary": "Summary",
    "Status": "Status",
    "Remarks": "Remarks",
    "Bearer": "Bearer",
    "Payoff Method": "Payoff Method",
    "Tax Rate": "Tax Rate",
    "Owner Bill": "Owner Bill",
    "created": "created",
    "waiting approval": "waiting approval",
    "approved": "approved",
    "rejected": "rejected",
    "payment in process": "payment in process",
    "cleaning fee": "cleaning fee",
    "advance payment fee": "advance payment fee",
    "emergency assistant fee": "emergency assistant fee",
    "adjustment fee": "adjustment fee",
    "RLS Burden": "RLS Burden",
    "Offset with Balance Reports": "Offset with Balance Reports",
  },
  "ja": {
    "Expenses": "経費",
    "Approve": "承認",
    "Reject": "却下",
    "Working day": "勤務日数",
    "Housekeeping Company": "清掃会社",
    "Housekeeper": "清掃担当者",
    "Property": "物件",
    "Cost": "金額",
    "Summary": "摘要",
    "Status": "ステータス",
    "Remarks": "備考",
    "Bearer": "負担者",
    "Payoff Method": "精算方法",
    "Tax Rate": "税率",
    "Owner Bill": "オーナー請求額",
    "created": "新規",
    "waiting approval": "未承認",
    "approved": "承認済み",
    "rejected": "却下",
    "payment in process": "支払い処理中",
    "cleaning fee": "清掃代",
    "advance payment fee": "駆けつけ対応",
    "emergency assistant fee": "立替金精算",
    "adjustment fee": "調整金",
    "RLS Burden": "当社負担",
    "Offset with Balance Reports": "収支報告書で相殺",
  }
}
</i18n>

<script>
import { saveAs } from 'file-saver';
import { getLocale } from '@/utils/locale';
import { getFullname } from '@/utils/users';
import { getDefaultLang } from '@/utils/language';
import Modal from '@/components/Modal';
import pagination from '@/components/mixins/pagination';
import ExpensesFilter from '@/views/reports/components/ExpensesFilter';
import ExpenseRejectForm from '@/views/reports/components/ExpenseRejectForm';
import ExpenseApproveForm from '@/views/reports/components/ExpenseApproveForm';

export default {
  name: 'Expenses',
  components: {
    Modal,
    ExpensesFilter,
    ExpenseRejectForm,
    ExpenseApproveForm,
  },
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('Working day'),
          dataIndex: 'workDate',
          width: 130,
          slots: { customRender: 'workDate' },
        },
        {
          title: this.$t('Housekeeping Company'),
          dataIndex: 'partner',
          key: 'partner',
          width: 150,
          slots: { customRender: 'partner' },
        },
        {
          title: this.$t('Housekeeper'),
          dataIndex: 'user',
          key: 'user',
          width: 150,
          slots: { customRender: 'user' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Cost'),
          dataIndex: 'cost',
          key: 'cost',
          slots: { customRender: 'cost' },
        },
        {
          title: this.$t('Summary'),
          dataIndex: 'summary',
          slots: { customRender: 'summary' },
        },
        {
          title: this.$t('Status'),
          key: 'status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Bearer'),
          key: 'bearer',
          dataIndex: 'bearer',
          slots: { customRender: 'bearer' },
        },
        {
          title: this.$t('Payoff Method'),
          key: 'payoffMethod',
          dataIndex: 'payoffMethod',
          slots: { customRender: 'payoffMethod' },
        },
        {
          title: this.$t('Tax Rate'),
          key: 'taxRate',
          dataIndex: 'taxRate',
          slots: { customRender: 'taxRate' },
        },
        {
          title: this.$t('Owner Bill'),
          key: 'ownerBill',
          dataIndex: 'ownerBill',
          slots: { customRender: 'ownerBill' },
        },
      ],
      selectedRows: [],
    };
  },
  computed: {
    rowSelection() {
      const selectedRowKeys = this.selectedRows ? this.selectedRows.map((row) => row.id) : [];

      return {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => ({
          ...record,
          disabled: record.status === 'approved',
        }),
      };
    },
    hasSelectedRows() {
      return this.selectedRows.length > 0;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    customRow(record) {
      return {
        onClick: () => {
          if (record.status === 'approved') {
            return false;
          }

          this.$router.push({
            name: 'expenses-edit',
            params: { id: record.id },
            query: { userId: record.userId },
          });

          return false;
        },
      };
    },
    async fetchData() {
      this.list = [];
      this.selectedRows = [];
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('reports/expenses', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;

          this.list = list;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleExportExcel() {
      const params = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
      };

      this.$store
        .dispatch('reports/expensesExport', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleExportCSV() {
      const params = { locale: getLocale() || getDefaultLang() };

      this.$store
        .dispatch('reports/expensesPaymentExport', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);

          this.fetchData();
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    getPartnerName(record) {
      return record.user
        && record.user.partner
        && record.user.partner[0]
        && (record.user.partner[0].name || '-');
    },
    getUserName({ user }) {
      if (!user) {
        return '-';
      }

      return getFullname({
        fname: user.firstName,
        lname: user.lastName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.report-table::v-deep {
  tbody tr:hover {
    cursor: pointer;
  }

  .approved:hover {
    cursor: no-drop;
  }
}

.btn-reject,
.btn-approve {
  min-width: 120px;
}
</style>
