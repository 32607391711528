<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Stock Control') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mini-gap is-mobi">
          <div class="column">
            <StocksFilter @clear-filter="resetList" @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <StockChartModal v-if="room && list.length > 0" :room="room" :list="list" />
          </div>
          <div class="column is-narrow">
            <a-button
              class="ant-btn ant-btn-lg ant-btn-primary"
              type="primary"
              @click="handleExportExcel"
            >
              {{ $t('Export to Excel') }}
            </a-button>
          </div>
        </div>
        <a-table
          v-if="isRenderTable"
          row-key="_id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :locale="tableLocale"
          :pagination="false"
          :scroll="{ x: 1250 }"
        >
          <template #stockName="{ record }">
            {{ record.stock ? record.stock.name : '-' }}
          </template>
          <template v-for="date in dates" :key="date" #[date]="{ record }">
            <StockCell :date="date" :name="record.name" :histories="record[date]" />
          </template>
          <template #filler />
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Stock Control": "Stock Control",
    "Stock Control Name": "Stock Control Name",
    "Primary Item Name": "Primary Item Name",
    "Secondary Item Name": "Secondary Item Name",
    "Show Pie Chart": "Show Pie Chart",
    "Please select property & room name": "Please select property & room name."
  },
  "ja": {
    "Stock Control": "在庫コントロール",
    "Stock Control Name": "在庫コントロール名",
    "Primary Item Name": "主アイテム名",
    "Secondary Item Name": "中項目アイテム名",
    "Show Pie Chart": "パイチャートの表示",
    "Please select property & room name": "物件と部屋を選択してください"
  }
}
</i18n>

<script>
import { saveAs } from 'file-saver';
import dateFilter from '@/filters/date';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import StockCell from '@/views/reports/components/StockCell';
import StocksFilter from '@/views/reports/components/StocksFilter';
import StockChartModal from '@/views/reports/components/StockChartModal';

export default {
  name: 'Stocks',
  components: {
    StockChartModal,
    StocksFilter,
    StockCell,
  },
  data() {
    return {
      isFetching: false,
      tableLocale: {
        emptyText: this.$t('Please select property & room name'),
      },
      list: [],
      room: null,
      dates: [],
      columns: [],
      baseColumns: [
        {
          title: this.$t('Stock Control Name'),
          fixed: 'left',
          dataIndex: 'stockName',
          width: 180,
          slots: { customRender: 'stockName' },
        },
        {
          title: this.$t('Primary Item Name'),
          fixed: 'left',
          dataIndex: 'category',
          width: 180,
          slots: { customRender: 'category' },
        },
        {
          title: this.$t('Secondary Item Name'),
          fixed: 'left',
          dataIndex: 'name',
          width: 180,
          className: 'item-name',
          slots: { customRender: 'name' },
        },
      ],
      isRenderTable: false,
    };
  },
  async mounted() {
    await this.setDateColumns();
    await this.setResponsiveColumns();
    window.addEventListener('resize', () => {
      this.setResponsiveColumns();
    });
  },
  methods: {
    async fetchData() {
      this.isFecthing = true;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      try {
        await Promise.all([this.fetchStocks(), this.fetchCurrentRoom()]);
      } finally {
        this.$store.commit('HIDE_FULLSCREEN_LOADER');
      }
    },
    fetchStocks() {
      this.list = [];
      this.dates = [];

      return this.$store.dispatch('reports/stocks', this.$route.query).then((resp) => {
        const { list, dates } = resp;

        this.list = list.map((item, index) => {
          const { histories, ...attr } = item;

          return {
            _id: index + 1,
            ...attr,
            ...histories,
          };
        });

        this.setDateColumns(dates);
      });
    },
    fetchCurrentRoom() {
      this.room = null;

      const { roomId } = this.$route.query;

      if (!roomId) {
        return null;
      }

      return this.$store.dispatch('rooms/get', { id: roomId }).then((data) => {
        this.room = data;
      });
    },
    setDateColumns(dates = []) {
      const dateColumns = dates.map((date) => ({
        title: dateFilter(date, { format: 'DD MMM' }),
        dataIndex: date,
        align: 'center',
        width: 100,
        slots: { customRender: date },
      }));

      this.dates = dates;
      this.columns = [
        ...this.baseColumns,
        ...dateColumns,
        {
          title: ' ',
          key: 'filler',
          slots: { customRender: 'filler' },
        },
      ];
    },
    setResponsiveColumns() {
      this.isRenderTable = false;
      const width = window.innerWidth;
      const columns = [...this.columns];

      this.columns = columns.map((col) => {
        if (col && ['name', 'category', 'stockName'].includes(col.dataIndex)) {
          if (width > 480) {
            col.fixed = 'left';
          } else {
            delete col.fixed;
          }
        }
        return col;
      });
      this.isRenderTable = true;
    },
    handleExportExcel() {
      const params = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
      };

      this.$store
        .dispatch('reports/stocksExport', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    resetList() {
      this.list = [];
      this.dates = [];
      this.room = null;
      this.setDateColumns();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ant-table-fixed-left .item-name {
  border-right: 1px solid #dcdcdc;
}
</style>
