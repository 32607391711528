<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Lost & Found') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div v-permission="['add-lost-and-found']" class="column is-narrow">
            <a-button class="ant-btn ant-btn-lg ant-btn-primary" @click="handleExportExcel">
              {{ $t('Export to Excel') }}
            </a-button>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #description="{ record }">
            {{ record.description }}
          </template>
          <template #property="{ record }">
            {{ record.propertyName }}
          </template>
          <template #room="{ record }">
            {{ record.roomName }}
          </template>
          <template #foundDate="{ record }">
            {{ $filters.date(record.createdAt, { format: $variables.dateFormat }) }}
          </template>
          <template #recoveredDate="{ record }">
            {{ $filters.date(record.recoveredDate, { format: $variables.dateFormat }) }}
          </template>
          <template #keptPeriod="{ record }">
            {{ record.keptPeriod ? record.keptPeriod + $t('day') : '-' }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import SearchForm from '@/views/reports/components/LostFoundsFilter';
import pagination from '@/components/mixins/pagination';

export default {
  name: 'LostFounds',
  components: {
    SearchForm,
  },
  mixins: [pagination],
  data() {
    return {
      columns: [
        {
          title: this.$t('Status'),
          dataIndex: 'status',
          key: 'status',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Lost & Found Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Description'),
          dataIndex: 'description',
          key: 'description',
          slots: { customRender: 'description' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'room',
          key: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Found date'),
          dataIndex: 'createdAt',
          slots: { customRender: 'foundDate' },
        },
        {
          title: this.$t('Recovered date'),
          dataIndex: 'recoveredDate',
          slots: { customRender: 'recoveredDate' },
        },
        {
          title: this.$t('Kept period'),
          dataIndex: 'keptPeriod',
          slots: { customRender: 'keptPeriod' },
        },
      ],
      list: [],
      isFetching: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.list = undefined;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('reports/lostFounds', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;
          this.list = list;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleExportExcel() {
      const params = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
      };
      this.$store
        .dispatch('reports/lostFoundsExport', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>

<i18n src="@/locales/components/lost-founds.json"></i18n>

<i18n>
{
  "en": {
    "Description": "Description",
    "Found date": "Found date",
    "Recovered date": "Recovered date",
    "Kept period": "Kept period",
  },
  "ja": {
    "Description": "説明",
    "Found date": "発見日",
    "Recovered date": "回収日",
    "Kept period": "保管期間",
  }
}
</i18n>
