<template>
  <a-popover v-model:visible="showPopover" title="" trigger="click">
    <template #content>
      <div class="stock-history">
        <div class="columns is-mini-gap is-mobile is-vcentered">
          <div class="column">
            <h1 class="has-text-primary has-text-weight-bold is-size-5">
              {{ name }}
            </h1>
            <h2 class="has-text-weight-bold is-size-7">
              {{ $t('usage for ') }} {{ $filters.date(date, { format: $variables.dateFormat }) }}
            </h2>
          </div>
        </div>
        <table v-if="Object.keys(users).length > 0" class="stock-history__table">
          <tbody>
            <tr v-for="(user, idx) in users" :key="idx">
              <th>{{ getUserFullname(user) }}</th>
              <td class="has-text-right">
                {{ user.used }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <span v-if="name" class="cell-date" @click="showPopover = true">
      {{ usedStocks }}
    </span>
    <span v-else>0</span>
  </a-popover>
</template>

<i18n>
{
  "en": {
    "usage for": "usage for"
  },
  "ja": {
    "usage for": "使用数"
  }
}
</i18n>

<script>
import { getFullname } from '@/utils/users';

export default {
  name: 'StockCell',
  props: {
    name: {
      type: String,
      default: null,
    },
    date: {
      type: String,
      default: null,
    },
    histories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  computed: {
    users() {
      const users = {};
      const { histories } = this;

      for (let i = 0; i < histories.length; i += 1) {
        const { used, user } = histories[i];

        if (!users[user.uid]) {
          users[user.uid] = { ...user, used: 0 };
        }

        users[user.uid].used += used;
      }

      return users;
    },
    usedStocks() {
      return this.histories.reduce((acc, value) => acc + value.used, 0);
    },
  },
  methods: {
    getUserFullname(record) {
      return getFullname({
        fname: record.firstName,
        lname: record.lastName,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cell-date {
  display: block;

  &:hover {
    cursor: pointer;
  }
}

.stock-history {
  width: 210px;

  padding-bottom: 10px;

  h2,
  th {
    color: #888888;
  }
}

.stock-history__table {
  width: 100%;

  th {
    font-weight: normal;
    padding: 2px 0;
    font-size: 12px;
  }

  td {
    color: #333333;
    padding: 2px 10px;
    font-size: 12px;
  }
}
</style>
