<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <InputHousekeeping
        v-model:value="partnerId"
        style="min-width: 200px"
        @change="delayedFilter"
      />
    </div>
    <div class="column is-narrow">
      <InputProperty v-model:value="propertyId" style="min-width: 200px" @change="delayedFilter" />
    </div>
    <div class="column is-narrow">
      <a-input-group compact>
        <a-select
          v-model:value="dateField"
          style="min-width: 110px"
          class="is-mobile-field"
          :placeholder="$t('Date Field')"
          :size="size"
          @change="handleFilter"
        >
          <a-select-option v-for="o in dateFieldOptions" :key="o.label" :value="o.value">
            {{ $t(o.label) }}
          </a-select-option>
        </a-select>
        <a-range-picker
          v-model:value="range"
          class="is-mobile-field"
          :size="size"
          :format="dateFormat"
          @change="handleFilter"
        >
          <a-input :value="rangeString" :placeholder="$t('date range')" :size="size" readonly>
            <template #prefix>
              <CalendarFilled />
            </template>
          </a-input>
        </a-range-picker>
      </a-input-group>
    </div>
    <div class="column">
      <a-button
        v-show="partnerId || propertyId || dateField || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Date Field": "Date Field",
    "date range": "date range",
    "Clear": "Clear",
    "Cleaning Date" : "Cleaning Date",
    "Start Date" : "Start Date",
    "Finish Date" : "Finish Date"
  },
  "ja": {
    "Date Field": "日付フィールド",
    "date range": "日付範囲",
    "Clear": "クリア",
    "Cleaning Date" : "清掃日",
    "Start Date" : "開始日",
    "Finish Date" : "終了日"
  }
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import InputHousekeeping from '@/components/InputHousekeeping';
import { cleanObject } from '@/utils/util';
import DateFormatMixin from '../mixins/DateFormat';

export default {
  name: 'HousekeepingFilter',
  components: {
    InputHousekeeping,
    InputProperty,
    CalendarFilled,
  },
  mixins: [DateFormatMixin],
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter'],
  data() {
    return {
      range: [],
      partnerId: undefined,
      propertyId: undefined,
      dateField: undefined,
      dateFieldOptions: [
        { label: 'Cleaning Date', value: 'cleaningDate' },
        { label: 'Start Date', value: 'startDate' },
        { label: 'Finish Date', value: 'finishDate' },
      ],
    };
  },
  mounted() {
    this.partnerId = this.$route.query.partnerId || undefined;
    this.propertyId = this.$route.query.propertyId || undefined;
    this.dateField = this.$route.query.dateField || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
  },
  methods: {
    // eslint-disable-next-line func-names
    delayedFilter: debounce(async function () {
      await this.handleFilter();
    }, 600),
    async handleFilter() {
      const query = cleanObject({
        ...this.$route.query,
        partnerId: this.partnerId,
        propertyId: this.propertyId,
        dateField: this.dateField,
        ...this.formattedRange,
        page: 1, // force page 1
      });

      await this.$router.push({ query });
      this.$emit('filter');
    },
    handleClear() {
      this.partnerId = undefined;
      this.propertyId = undefined;
      this.dateField = undefined;
      this.range = [];
      this.handleFilter();
    },
  },
};
</script>

<style scoped>
::v-deep .ant-input-group.ant-input-group-compact > .ant-calendar-picker .ant-input-affix-wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media screen and (max-width: 360px) {
  ::v-deep .is-mobile-field {
    width: 100%;
    margin-bottom: 5px;
    border-radius: 5px !important;
  }
}
</style>
