<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Out of Order') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm @filter="fetchData" />
          </div>
          <div v-permission="['add-out-of-order']" class="column is-narrow">
            <a-button
              class="ant-btn ant-btn-lg ant-btn-primary"
              type="default"
              @click="handleExportExcel"
            >
              {{ $t('Export to Excel') }}
            </a-button>
          </div>
        </div>
        <a-table
          row-key="id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #id="{ text }">
            {{ text }}
          </template>
          <template #name="{ record }">
            {{ record.name }}
          </template>
          <template #property="{ record }">
            {{ record.propertyName }}
          </template>
          <template #room="{ record }">
            {{ record.roomName }}
          </template>
          <template #reportedTime="{ record }">
            {{ $filters.date(record.startDate, { format: $variables.dateFormat }) }}
          </template>
          <template #endDate="{ record }">
            {{ $filters.date(record.endDate, { format: $variables.dateFormat }) }}
          </template>
          <template #period="{ record }">
            {{ record.period ? record.period + $t('Days') : '-' }}
          </template>
          <template #status="{ record }">
            {{ $t(record.status) }}
          </template>
          <template #action="{ record }">
            <Modal>
              <template #default="{ hide }">
                <DelOutOfOrder
                  v-permission="['modify-out-of-order']"
                  :out-of-order="record"
                  @close="
                    () => {
                      hide();
                      fetchData();
                    }
                  "
                  @cancel="hide"
                />
              </template>
              <template #handler="{ show }">
                <DeleteFilled
                  v-permission="['modify-out-of-order']"
                  class="has-text-danger"
                  @click.stop.prevent="show"
                />
              </template>
            </Modal>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Out of Order": "Out of Order",
    "New Out of Order": "New Out of Order",
    "Out of Order Name": "Out of Order Name",
    "Out of Order Description": "Description",
    "Property": "Property",
    "Room Name": "Room Name",
    "Reported time": "Reported time",
    "Start Date": "Start Date",
    "End Date": "End Date",
    "Out of Order Period": "Out of Order Period",
    "Status": "Status",
    "Days": " day(s)",
    "Export to Excel": "Export to Excel"
  },
  "ja": {
    "Out of Order": "使用不可",
    "New Out of Order": "新規使用不可",
    "Out of Order Name": "使用不可 アイテム名",
    "Out of Order Description": "詳細",
    "Property": "物件",
    "Room Name": "部屋番号",
    "Reported time": "報告時間",
    "Start Date": "最初日",
    "End Date": "最終日",
    "Out of Order Period": "使用不可時期",
    "Status": "ステータス",
    "Days": "日",
    "Export to Excel": "エクセルにエクスポート"
  }
}
</i18n>

<script>
import { saveAs } from 'file-saver';
import { DeleteFilled } from '@ant-design/icons-vue';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import Modal from '@/components/Modal';
import SearchForm from '@/views/reports/components/out-of-order/SearchForm';
import DelOutOfOrder from '@/views/out-of-order/components/DelOutOfOrder';
import pagination from '@/components/mixins/pagination';

export default {
  name: 'OutOfOrder',
  components: {
    Modal,
    DelOutOfOrder,
    SearchForm,
    DeleteFilled,
  },
  mixins: [pagination],
  data() {
    return {
      columns: [
        {
          title: this.$t('Status'),
          key: 'isActive',
          dataIndex: 'isActive',
          slots: { customRender: 'status' },
        },
        {
          title: this.$t('Out of Order Name'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Out of Order Description'),
          dataIndex: 'description',
          slots: { customRender: 'description' },
        },
        {
          title: this.$t('Property'),
          dataIndex: 'property',
          key: 'property',
          slots: { customRender: 'property' },
        },
        {
          title: this.$t('Room Name'),
          dataIndex: 'room',
          key: 'room',
          slots: { customRender: 'room' },
        },
        {
          title: this.$t('Start Date'),
          dataIndex: 'reportedTime',
          slots: { customRender: 'reportedTime' },
        },
        {
          title: this.$t('End Date'),
          dataIndex: 'endDate',
          slots: { customRender: 'endDate' },
        },
        {
          title: this.$t('Out of Order Period'),
          dataIndex: 'period',
          slots: { customRender: 'period' },
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.list = undefined;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('reports/outOfOrder', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;
          this.list = list;
          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleExportExcel() {
      const params = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
      };

      this.$store
        .dispatch('reports/outOfOrderExport', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>
