<template>
  <div class="columns form is-mini-gap is-multiline">
    <div v-show="!room" class="column is-narrow">
      <InputProperty v-model:value="propertyId" style="min-width: 200px" @change="debounceQuery" />
    </div>
    <div v-show="!room" class="column is-narrow">
      <InputRoom
        v-model:value="roomId"
        :property-id="propertyId"
        style="min-width: 200px"
        @change="debounceQuery"
      />
    </div>
    <div class="column is-narrow">
      <a-select
        v-model:value="status"
        :placeholder="$t('Status')"
        style="min-width: 200px; width: 100%"
        :size="size"
        @change="debounceQuery"
      >
        <a-select-option v-for="o in statusOptions" :key="o.label" :value="o.value">
          {{ $t(o.value) }}
        </a-select-option>
      </a-select>
    </div>
    <div class="column is-narrow">
      <a-input-group compact>
        <a-select
          v-model:value="dateSelection"
          class="is-mobile-field"
          :placeholder="$t('Choose date')"
          style="min-width: 120px"
          :size="size"
        >
          <a-select-option v-for="o in dateOptions" :key="$t(o.label)" :value="o.value">
            {{ $t(o.label) }}
          </a-select-option>
        </a-select>
        <a-range-picker
          v-model:value="range"
          class="is-mobile-field"
          :size="size"
          :format="dateFormat"
          @change="debounceQuery"
        >
          <a-input
            :value="rangeString"
            :placeholder="$t(`${dateSelectionText} range`)"
            :size="size"
            readonly
          >
            <template #prefix>
              <CalendarFilled />
            </template>
          </a-input>
        </a-range-picker>
      </a-input-group>
    </div>
    <div class="column">
      <a-button
        v-show="propertyId || roomId || status || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
"en":{
"Found date range": "Found date range",
"Recovered date range": "Recovered date range",
"Found date": "Found date",
"Recovered date": "Recovered date",
"Date range": "Date range",
"Clear": "Clear",
"Status": "Status",
"Choose date": "Choose date"
},
"ja":{
"Found date range": "見つかった日付範囲",
"Recovered date range": "回収された日付範囲",
"Found date": "発見日",
"Recovered date": "回収日",
"Date range": "日付範囲",
"Clear": "クリア",
"Status": "状態",
"Choose date": "日付を選択"
}
}
</i18n>

<script>
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputRoom from '@/components/InputRoom';
import InputProperty from '@/components/InputProperty';
import { STATUS, DATES } from '@/config/lost-and-found';
import { cleanObject } from '@/utils/util';
import DateFormatMixin from '../mixins/DateFormat';

export default {
  name: 'SearchForm',
  components: {
    InputProperty,
    InputRoom,
    CalendarFilled,
  },
  mixins: [DateFormatMixin],
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
    room: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  emits: ['filter'],
  data() {
    return {
      propertyId: undefined,
      roomId: undefined,
      range: [],
      status: undefined,
      statusOptions: STATUS,
      dateOptions: DATES,
      dateSelection: undefined,
    };
  },
  computed: {
    dateSelectionText() {
      if (this.dateSelection) {
        return this.dateOptions.find((object) => object.value === this.dateSelection).label;
      }
      return 'Date';
    },
  },
  mounted() {
    const propertyId = this.property ? this.property.newPropertyCode : undefined;

    this.propertyId = propertyId || this.$route.query.propertyId || undefined;
    this.roomId = this.$route.query.roomId || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
    this.status = this.$route.query.status || undefined;
  },
  methods: {
    // eslint-disable-next-line func-names
    debounceQuery: debounce(async function () {
      const query = cleanObject({
        ...this.$route.query,
        propertyId: this.propertyId,
        roomId: this.roomId,
        ...this.formattedRange,
        status: this.status,
        page: 1, // force page 1
        dateField: this.dateSelection,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    }, 600),
    handleClear() {
      this.propertyId = undefined;
      this.roomId = undefined;
      this.status = undefined;
      this.dateSelection = undefined;
      this.range = [];
      this.debounceQuery();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.no-right-border-radius .ant-select-selector) {
  border-radius: 5px 0px 0px 5px;
}

:deep(.no-left-border-radius .ant-input-affix-wrapper) {
  border-radius: 0px 5px 5px 0px;
}
</style>

<style scoped>
@media screen and (max-width: 370px) {
  .is-mobile-field {
    width: 100%;
    margin-bottom: 5px;
    border-radius: 5px !important;
  }
}
</style>
