<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <InputProperty v-model:value="propertyId" style="min-width: 150px" />
    </div>
    <div class="column is-narrow">
      <InputRoom
        v-model:value="roomId"
        :property-id="propertyId"
        :disabled="!propertyId"
        style="min-width: 150px"
      />
    </div>
    <div class="column is-narrow">
      <a-range-picker v-model:value="range" style="width: 100%" :size="size" :format="dateFormat">
        <a-input :value="rangeString" :placeholder="$t('date range')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div class="column is-narrow">
      <a-button
        v-show="roomId && propertyId"
        :size="size"
        style="min-width: 110px"
        type="primary"
        @click="handleFilter"
      >
        {{ $t('Search') }}
      </a-button>
    </div>
    <div class="column">
      <a-button
        v-show="roomId || propertyId || range.length"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "date range": "date range",
    "Search": "Search",
    "Clear": "Clear",
    "History Date" : "History Date",
  },
  "ja": {
    "date range": "日付範囲",
    "Search": "サーチ",
    "Clear": "クリア",
    "History Date" : "過去の日付",
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import InputRoom from '@/components/InputRoom';
import { cleanObject } from '@/utils/util';
import DateFormatMixin from '../mixins/DateFormat';

export default {
  name: 'StocksFilter',
  components: {
    InputProperty,
    InputRoom,
    CalendarFilled,
  },
  mixins: [DateFormatMixin],
  props: {
    size: {
      type: String,
      default: 'large',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },
  emits: ['filter', 'clear-filter'],
  data() {
    return {
      range: [],
      roomId: undefined,
      propertyId: undefined,
    };
  },
  mounted() {
    this.roomId = this.$route.query.roomId || undefined;
    this.propertyId = this.$route.query.propertyId || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
  },
  methods: {
    // eslint-disable-next-line func-names
    delayedFilter: debounce(async function () {
      await this.handleFilter();
    }, 600),
    async handleFilter() {
      if (!this.rangeString) {
        this.setDefaultDateRange();
      }

      const query = cleanObject({
        ...this.$route.query,
        roomId: this.roomId,
        propertyId: this.propertyId,
        ...this.formattedRange,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    },
    setDefaultDateRange() {
      const now = dayjs().format(this.dateFormat);
      const weekAgo = dayjs().subtract(7, 'days').format(this.dateFormat);

      this.range = [weekAgo, now];
    },
    handleClear() {
      this.roomId = undefined;
      this.propertyId = undefined;
      this.range = [];
      this.$emit('clear-filter');
    },
  },
};
</script>

<style scoped></style>
