<template>
  <div class="columns form is-mini-gap is-multiline">
    <div class="column is-narrow">
      <InputProperty
        v-model:value="propertyId"
        displayed-text="abbreviation"
        style="min-width: 200px"
        @change="onChangeFilter"
      />
    </div>
    <div class="column is-narrow">
      <a-range-picker
        v-model:value="range"
        style="width: 100%"
        :size="size"
        :format="dateFormat"
        @change="onChangeFilter"
      >
        <a-input :value="rangeString" :placeholder="$t('Date range')" :size="size" readonly>
          <template #prefix>
            <CalendarFilled />
          </template>
        </a-input>
      </a-range-picker>
    </div>
    <div class="column is-narrow">
      <div class="is-flex is-align-items-center">
        <div class="mx-2">
          {{ $t('Base') }}
        </div>
        <div>
          <a-input-number
            v-model:value="base"
            :placeholder="$t('Base')"
            :size="size"
            min="0"
            @change="onChangeFilter"
          />
        </div>
      </div>
    </div>
    <div class="column is-narrow">
      <a-button
        v-show="hasFilters"
        :size="size"
        style="min-width: 110px"
        type="default"
        @click="handleClear"
      >
        {{ $t('Clear') }}
      </a-button>
    </div>
    <div v-show="hasFilters" class="flex-center">
      <div class="mx-2 title is-6 mb-0">
        {{ $t('Housekeeping Total') }}
      </div>
      <div class="title is-6 mb-0">
        {{ houseKeepingTotal }}
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Housekeeping Total": "Housekeeping Total",
    "Date Range": "Date Range",
    "Base": "Base",
    "Clear": "Clear"
  },
  "ja": {
    "Housekeeping Total": "Housekeeping Total",
    "Date Range": "Date Range",
    "Base": "Base",
    "Clear": "Clear"
  }
}
</i18n>

<script>
import dayjs from 'dayjs';
import debounce from 'lodash/debounce';
import { CalendarFilled } from '@ant-design/icons-vue';
import InputProperty from '@/components/InputProperty';
import { cleanObject } from '@/utils/util';
import { BASE_RESERVATION_COUNTER } from '@/config/reservations';
import DateFormatMixin from '../mixins/DateFormat';

export default {
  name: 'ReservationCounterFilter',
  components: {
    InputProperty,
    CalendarFilled,
  },
  mixins: [DateFormatMixin],
  props: {
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
    houseKeepingTotal: {
      type: Number,
      default: 0,
    },
  },
  emits: ['filter'],
  data() {
    return {
      size: 'large',
      propertyId: undefined,
      base: BASE_RESERVATION_COUNTER,
      range: [],
    };
  },
  computed: {
    hasFilters() {
      return this.propertyId || this.base !== BASE_RESERVATION_COUNTER || this.range.length;
    },
  },
  watch: {
    propertyId: {
      handler(nv) {
        if (nv && !this.range.length) {
          const format = 'YYYY-MM-DD';
          const from = dayjs().startOf('month').format(format);
          const to = dayjs(from).add(1, 'month').subtract(1, 'day').format(format);
          this.range = [from, to];
        }
      },
    },
  },
  mounted() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.range = this.$route.query.dateFrom && this.$route.query.dateTo
      ? [this.$route.query.dateFrom, this.$route.query.dateTo]
      : [];
    this.base = this.$route.query.base || BASE_RESERVATION_COUNTER;
  },
  methods: {
    onChangeFilter: debounce(async function onChangeFilter() {
      this.handleFilter();
    }, 600),
    async handleFilter() {
      const query = cleanObject({
        ...this.$route.query,
        ...this.formattedRange,
        propertyId: this.propertyId,
        base: this.base,
      });

      await this.$router.push({ query });
      this.$emit('filter');
    },
    handleClear() {
      this.propertyId = undefined;
      this.base = BASE_RESERVATION_COUNTER;
      this.range = [];
      this.onChangeFilter();
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-center {
  display: flex;
  align-items: center;
}
</style>
