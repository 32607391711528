<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Reservation Counter') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <SearchForm :house-keeping-total="houseKeepingTotal" @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <a-button
              class="ant-btn ant-btn-lg ant-btn-primary"
              :loading="isExporting"
              @click="handleExportTable"
            >
              {{ $t('Export to Excel') }}
            </a-button>
          </div>
        </div>

        <a-config-provider>
          <template v-if="!propertyId" #renderEmpty>
            <div style="text-align: center">
              <p>{{ $t('property-not-selected') }}</p>
            </div>
          </template>

          <a-table
            row-key="id"
            :columns="columns"
            :data-source="list"
            :loading="isFetching"
            :pagination="pagination"
            @change="handleTableChange"
          />
        </a-config-provider>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Reservation Counter": "Reservation Counter",
    "Room Number": "Room Number",
    "No. of Reservation": "No. of Reservation",
    "Rooms left (base - No. of Reservation)": "Rooms left (base - No. of Reservation)",
    "No. of Blocked Nights": "No. of Blocked Nights",
    "No. of Housekeeping": "No. of Housekeeping",
    "Occupancy Rate": "Occupancy Rate",
    "property-not-selected": "Please select property ID first to show the data."
  },
  "ja": {
    "Reservation Counter": "予約数カウンター",
    "Room Number": "部屋番号",
    "No. of Reservation": "予約日数",
    "Rooms left (base - No. of Reservation)": "残数（基準数-予約日数）",
    "No. of Blocked Nights": "ブロック数",
    "No. of Housekeeping": "清掃回数",
    "Occupancy Rate": "稼働率",
    "property-not-selected": "Please select property ID first to show the data."
  }
}
</i18n>

<script>
import saveAs from 'file-saver';
import SearchForm from './components/ReservationCounterFilter';
import pagination from '@/components/mixins/pagination';
import { getDefaultLang } from '@/utils/language';
import { getLocale } from '@/utils/locale';
import { BASE_RESERVATION_COUNTER } from '@/config/reservations';

export default {
  name: 'ReservationCounter',
  components: {
    SearchForm,
  },
  mixins: [pagination],
  data() {
    return {
      propertyId: undefined,
      isExporting: false,
      list: undefined,
      isFetching: false,
      houseKeepingTotal: 0,
      columns: [
        {
          title: this.$t('Room Number'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('No. of Reservation'),
          dataIndex: 'reservationCount',
          align: 'center',
          slots: { customRender: 'reservationCount' },
        },
        {
          title: this.$t('Rooms left (base - No. of Reservation)'),
          dataIndex: 'roomLeft',
          align: 'center',
          slots: { customRender: 'roomLeft' },
        },
        {
          title: this.$t('No. of Blocked Nights'),
          dataIndex: 'blockedNight',
          align: 'center',
          slots: { customRender: 'blockedNight' },
        },
        {
          title: this.$t('No. of Housekeeping'),
          dataIndex: 'housekeeping',
          align: 'center',
          slots: { customRender: 'housekeeping' },
        },
        {
          title: this.$t('Occupancy Rate'),
          dataIndex: 'occupancyRate',
          align: 'center',
          slots: { customRender: 'occupancyRate' },
        },
      ],
    };
  },
  watch: {
    '$route.query': {
      handler({ propertyId }) {
        this.propertyId = propertyId;
      },
      deep: true,
    },
  },
  created() {
    this.propertyId = this.$route.query.propertyId || undefined;
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      await this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.isFetching = true;

      if (this.propertyId) {
        const { list, pagination } = await this.$store
          .dispatch('reports/reservationCounter', {
            ...this.$route.query,
            base: this.$route.query.base || BASE_RESERVATION_COUNTER,
          });

        this.list = list;
        this.setPagination(pagination);

        const { total } = await this.$store
          .dispatch('reports/reservationCounterHouseKeepingTotal', {
            ...this.$route.query,
            base: this.$route.query.base || BASE_RESERVATION_COUNTER,
          });

        this.houseKeepingTotal = total;
      } else {
        this.list = undefined;
      }

      this.isFetching = false;
      await this.$store.commit('HIDE_FULLSCREEN_LOADER');
    },
    handleExportTable() {
      const params = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
        base: this.$route.query.base || BASE_RESERVATION_COUNTER,
      };

      this.isExporting = true;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store.dispatch('reports/reservationCounterExport', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.isExporting = false;
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>
