<template>
  <div class="page">
    <section class="hero is-banner is-primary">
      <div class="hero-body">
        <div class="container">
          <div class="columns is-mobile">
            <div class="column">
              <h2 class="is-3 title">
                {{ $t('Housekeeping Company') }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="section">
      <div class="container">
        <div class="columns is-mobi">
          <div class="column">
            <HousekeepingFilter @filter="fetchData" />
          </div>
          <div class="column is-narrow">
            <a-button
              class="ant-btn ant-btn-lg ant-btn-primary"
              type="default"
              @click="handleExportExcel"
            >
              {{ $t('Export to Excel') }}
            </a-button>
          </div>
        </div>
        <a-table
          row-key="_id"
          :columns="columns"
          :data-source="list"
          :loading="isFetching"
          :custom-row="rowClicked"
          :pagination="pagination"
          @change="handleTableChange"
        >
          <template #workDays="{ record }">
            {{ record.workDays }}
          </template>
          <template #totalRooms="{ record }">
            {{ record.totalRooms }}
          </template>
          <template #noShowCount="{ record }">
            {{ record.noShowCount }}
          </template>
          <template #avgRoomsPerDay="{ record }">
            {{ record.avgRoomsPerDay }}
          </template>
          <template #workMinutes="{ record }">
            {{ record.workMinutes }}
          </template>
          <template #avgMinsPerDay="{ record }">
            {{ record.avgMinsPerDay }}
          </template>
          <template #avgMinsPerRoom="{ record }">
            {{ record.avgMinsPerRoom }}
          </template>
          <template #avgGuestResponse="{ record }">
            {{ record.avgGuestResponse }}
          </template>
          <template #rejectCount="{ record }">
            {{ record.rejectCount }}
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "Housekeeping Company": "Housekeeping Company",
    "Total work days": "Total work days",
    "Total rooms": "Total rooms",
    "No Show": "No Show",
    "Average rooms/day": "Average rooms/day",
    "Total minutes": "Total minutes",
    "Average minutes/day": "Average minutes/day",
    "Average minutes/room": "Average minutes/room",
    "Average guest response rating": "Average guest response rating",
    "Total rejected": "Total rejected"
  },
  "ja": {
    "Housekeeping Company": "清掃会社",
    "Total work days": "総労働日数",
    "Total rooms": "総部屋数",
    "No Show": "清掃不要",
    "Average rooms/day": "1日あたりの平均客室数",
    "Total minutes": "合計分",
    "Average minutes/day": "1日あたりの平均分",
    "Average minutes/room": "平均分/部屋",
    "Average guest response rating": "ゲストの平均応答評価",
    "Total rejected": "拒否された合計"
  }
}
</i18n>

<script>
import { saveAs } from 'file-saver';
import { getLocale } from '@/utils/locale';
import { getDefaultLang } from '@/utils/language';
import pagination from '@/components/mixins/pagination';
import HousekeepingFilter from '@/views/reports/components/HousekeepingFilter';

export default {
  name: 'HousekeepingCompanies',
  components: {
    HousekeepingFilter,
  },
  mixins: [pagination],
  data() {
    return {
      list: undefined,
      isFetching: false,
      columns: [
        {
          title: this.$t('Housekeeping Company'),
          dataIndex: 'name',
          slots: { customRender: 'name' },
        },
        {
          title: this.$t('Total work days'),
          dataIndex: 'workDays',
          slots: { customRender: 'workDays' },
        },
        {
          title: this.$t('Total rooms'),
          dataIndex: 'totalRooms',
          slots: { customRender: 'totalRooms' },
        },
        {
          title: this.$t('No Show'),
          dataIndex: 'noShowCount',
          slots: { customRender: 'noShowCount' },
        },
        {
          title: this.$t('Average rooms/day'),
          dataIndex: 'avgRoomsPerDay',
          slots: { customRender: 'avgRoomsPerDay' },
        },
        {
          title: this.$t('Total minutes'),
          dataIndex: 'workMinutes',
          slots: { customRender: 'workMinutes' },
        },
        {
          title: this.$t('Average minutes/day'),
          dataIndex: 'avgMinsPerDay',
          slots: { customRender: 'avgMinsPerDay' },
        },
        {
          title: this.$t('Average minutes/room'),
          dataIndex: 'avgMinsPerRoom',
          slots: { customRender: 'avgMinsPerRoom' },
        },
        {
          title: this.$t('Average guest response rating'),
          dataIndex: 'avgGuestResponse',
          slots: { customRender: 'avgGuestResponse' },
        },
        {
          title: this.$t('Total rejected'),
          dataIndex: 'rejectCount',
          slots: { customRender: 'rejectCount' },
        },
      ],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    async handleTableChange(pagination) {
      await this.preparePagination(pagination);
      await this.fetchData();
    },
    async fetchData() {
      this.list = undefined;
      this.$store.commit('SHOW_FULLSCREEN_LOADER');
      this.$store
        .dispatch('reports/housekeepingCompanies', this.$route.query)
        .then((resp) => {
          const { list, pagination } = resp;

          this.list = list.map((p, index) => {
            p._id = index + 1;
            return p;
          });

          this.setPagination(pagination);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
    handleExportExcel() {
      const params = {
        ...this.$route.query,
        locale: getLocale() || getDefaultLang(),
      };

      this.$store
        .dispatch('reports/housekeepingCompaniesExport', params)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
</script>
